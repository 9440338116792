require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")

import 'babel-polyfill'
import 'whatwg-fetch'

import Vue from 'vue/dist/vue.esm';
import '../styles/application';
import '@fortawesome/fontawesome-free';

import filters from './filters';
for (let name in filters) {
  Vue.filter(name, filters[name]);
}

import Axios from './axios';
Vue.prototype.$axios = Axios;

import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');
Vue.prototype.$dayjs = dayjs;

import lodash from 'lodash';
Vue.prototype._ = lodash;
import Toasted from 'vue-toasted';

import vueAwesomeCountdown from 'vue-awesome-countdown';
Vue.use(vueAwesomeCountdown, 'vac');

import vSelect from 'vue-select';
Vue.component('v-select', vSelect);

import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

Vue.component('VueSlider', VueSlider);

require.context('../images', true);
require('vue2-animate/dist/vue2-animate.min.css');

import VueLazyload from 'vue-lazyload';
Vue.use(VueLazyload);

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

import Vue2TouchEvents from 'vue2-touch-events'
Vue.use(Vue2TouchEvents)

import VTooltip from 'v-tooltip'
const customTooltipOptions = {
  defaultPlacement: 'top',
  defaultClass: 'tooltip-custom',
  defaultTrigger: 'hover focus',
  autoHide: false
}

Vue.use(VTooltip, customTooltipOptions)

const ellipsisText = (e, length) => {
  if (e.innerHTML.length > length) {
    e.innerHTML = e.innerHTML.substring(0, length) + ("...");
  }
};

Vue.directive('plaintext', {
  bind: function (el, binding, vnode) {
    el.innerHTML = el.innerText;
    ellipsisText(el, binding.value);
  }
});

import Loading from './components/Shared/Loading';

import store from "./store";

document.addEventListener('DOMContentLoaded', () => {
  document.getElementById("app").classList.add("animated", "fadeIn");

  const vue = new Vue({
    el: "#app",
    store,
    components: {
      HeaderMenu: () => import('./components/HeaderMenu'),
      WelcomeSlider: () => import('./components/WelcomeSlider'),
      OurServiceSection: () => import('./components/OurServiceSection'),
      SupportSection: () => import('./components/SupportSection'),
      BenefitSection: () => import('./components/BenefitSection'),
      FooterSection: () => import('./components/FooterSection'),
      LoginModal: () => import('./components/LoginModal'),
      LoginButton: () => import('./components/LoginButton'),
      ProductModal: () => import('./components/Products/ProductModal'),
      FavoriteList: () => import("./components/Products/FavoriteList"),
      RecommendProductsList: () => import('./components/Products/RecommendList'),
      BestSellingProductsList: () => import('./components/Products/BestSellingList'),
      NewsHomeList: () => import('./components/News/HomeList'),
      NewsIndex: () => import('./components/News/Index'),
      ProductDetail: () => import('./components/Products/Detail'),
      CustomerFeedbacks: () => import('./components/CustomerFeedbacks'),
      AddToCartModal: () => import('./components/Products/AddToCartModal'),
      ProductRating: () => import('./components/Products/ProductParts/Rating'),
      CartIndex: () => import('./components/Checkout/Cart'),
      PrepareIndex: () => import('./components/Checkout/Prepare'),
      ReceiverInfoModal: () => import('./components/ReceiverInfoModal'),
      SenderInfoModal: () => import('./components/SenderInfoModal'),
      PlateInfoModal: () => import('./components/PlateInfoModal'),
      EditDeliveryMemoModal: () => import('./components/EditDeliveryMemoModal'),
      PlateWarningModal: () => import('./components/PlateWarningModal'),
      MessageCardModal: () => import('./components/MessageCardModal'),
      ProductsIndex: () => import('./components/Products/ProductsIndex'),
      ShippingIndex: () => import('./components/Checkout/Shipping'),
      ShippingDemandDateModal: () => import('./components/ShippingDemandDateModal'),
      PaymentIndex: () => import('./components/Checkout/Payment'),
      ConfirmIndex: () => import('./components/Checkout/Confirm'),
      CompleteIndex: () => import('./components/Checkout/Complete'),
      NpPaymentModal: () => import('./components/NpPaymentModal'),
      SendersIndex: () => import('./components/Senders'),
      PlatesIndex: () => import('./components/Plates'),
      ReceiversIndex: () => import('./components/Receivers'),
      AlertsIndex: () => import('./components/Alerts'),
      InvoicesIndex: () => import('./components/Invoices'),
      UsersIndex: () => import('./components/Users'),
      OrdersIndex: () => import('./components/Orders'),
      OrderDetail: () => import('./components/Orders/Detail'),
      OrderReviewsIndex: () => import('./components/Reviews'),
      ArticlesIndex: () => import('./components/Articles'),
      PointsIndex: () => import('./components/Points'),
      GiftsIndex: () => import('./components/Gifts'),
      Loading: Loading,
      OrdersCsv: () => import('./components/Orders/CsvPage'),
      UserRelationsIndex: () => import('./components/UserRelations'),
      UserRelationDetail: () => import('./components/UserRelations/Detail'),
      ImportCsvErrorModal:() => import('./components/ImportCsv/ImportCsvErrorModal.vue'),
      ImportPreviewModal:() => import('./components/ImportCsv/ImportPreviewModal.vue'),
      ImportCsvIndex: () => import('./components/ImportCsv')
    }
  });

  Vue.use(Toasted);
});